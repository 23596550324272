/* CustomCard.css */

.custom-card-body .p-card-body {
    border-color: #c0c0c0;
    border: 2px solid #c0c0c0;
    padding: 0px;
    border-radius: 6px;
}

.custom-card-title .p-card-title {
    padding: 10px;
    background-color: #c0c0c0;
    font-size: 1.2rem;
}

.custom-card-content .p-card-content {
    background-color: #ffffff;
    padding: 10px;
}

